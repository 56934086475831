import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Button,
  Segment,
  Form,
  Dropdown,
  Divider,
  Icon,
} from "semantic-ui-react";
import UploadModal from "../../libs/UploadModal";

import { update_Pathway, fetchPages } from "../../actions";
import { s3Upload } from "../../libs/awsLib";
import s3config from "../../s3config";
import config from "../../config";
import treatment_stage from "./treatment_stage.json";
import { faAddressCard } from "@fortawesome/free-solid-svg-icons";

import TimelineModal from "./TimelineModal";

import TimelineCard from "./TimelineCard";
const Timeline = ({ pathway }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const pages = useSelector((state) => state.pages);

  const [uploadModalOpen, update_uploadModalOpen] = useState(false);
  const [modal_open, update_modal_open] = useState(false);
  const [data, update_data] = useState([]);

  useEffect(() => {
    update_data(JSON.parse(pathway.timeline_data));
    console.log(pathway.timeline_data);
  }, [pathway]);

  useEffect(() => {
    dispatch(fetchPages(user["custom:trust"]));
  }, [pathway]);

  function addNewSection() {
    var arr = [...data];
    const params = {
      id: "1",
      title: "",
      stage_id: "1",
      card_array: [
        {
          id: "1",
          title: "New Card",
          step: "Step 1",
          destination: "",
          image: null,
        },
      ],
    };
    arr.push(params);
    update_data(arr);
    saveData(arr);
  }

  function removeSection(section, index) {
    var arr = [...data];
    arr.pop(index);
    update_data(arr);
    saveData(arr);
  }

  function saveData(data_sent_to_function) {
    const data_to_save = data_sent_to_function || data;
    const params = {
      id: pathway.id,
      timeline_data: JSON.stringify(data_to_save),
    };
    console.log(params);

    dispatch(update_Pathway(params));
  }

  function handleOpenModal() {
    update_modal_open(!modal_open);
  }

  // UPDATE CONTENT FUNCTION

  function handleChange(type, event, index) {
    var new_id = Date.now();
    var arr = [...data];
    console.log("new_id");
    console.log(new_id);
    switch (type) {
      case "title":
        arr[index].title = event.target.value;
        update_data(arr);
        break;
      case "treatment_stage":
        arr[index].stage_id = event;
        update_data(arr);
        break;
      case "add_card":
        console.log(arr[index].card_array);
        arr[index].card_array.push({
          id: new_id.toString(),
          title: "New Card",
          step: "Step 1",
          destination: "",
          image: null,
        });
        console.log(arr);
        update_data(arr);
        saveData(arr);
        break;
      default:
        break;
    }
  }

  async function handleChangeCard(type, event, form_index, index) {
    var arr = [...data];
    console.log(event);
    switch (type) {
      case "title":
        arr[form_index].card_array[index].title = event.target.value;
        update_data(arr);
        break;
      case "step":
        arr[form_index].card_array[index].step = event.target.value;
        update_data(arr);
        break;
      case "destination":
        console.log(event);
        arr[form_index].card_array[index].destination = event;
        update_data(arr);
        break;

      case "image":
        console.log("event");
        console.log(event);
        arr[form_index].card_array[index].image = event;
        update_data(arr);
        break;
      default:
        break;
    }
  }

  async function upload(event, form_index, index) {
    console.log("running upload function");
    //event.preventDefault();
    //this.setState({uploadModalOpen: true})
    const file = event.target.files[0];
    console.log(file);

    if (file && file.size > config.MAX_ATTACHMENT_SIZE) {
      alert(
        `Please pick a file smaller than ${
          config.MAX_ATTACHMENT_SIZE / 1000000
        } MB.`
      );
      return;
    }
    try {
      update_uploadModalOpen(true);
      console.log("trying to upload file");
      const newFile = await s3Upload(file);
      console.log(newFile);
      const public_url = s3config.S3.URL + s3config.S3.EXT + newFile;
      handleChangeCard("image", public_url, form_index, index);
      update_uploadModalOpen(false);

      //console.log(state_object)
    } catch (e) {
      alert(e);
      console(e);
    }
  }

  function deleteCard(form_index, index) {
    console.log("delete");
    console.log(data);
    console.log(form_index);
    console.log(index);
    var arr = [...data];
    // console.log(arr);
    arr[form_index].card_array.splice(index, 1);
    console.log(arr);
    saveData(arr);
  }
  function image_update(image, index, form_index) {
    console.log("now we are inside this function");
    console.log(image);
    var arr = [...data];
    arr[form_index].card_array[index].hash = image.hash;
    arr[form_index].card_array[index].image = image.image_url;
    arr[form_index].card_array[index].image_type = "external";
    update_data(arr);
  }

  function returnTimeline() {
    const form = data.map((section, index) => {
      const form_index = index;
      return (
        <Segment secondary>
          <h3>Title Segment</h3>
          <Form>
            <Form.Field>
              <label>
                Title:
                <input
                  name="title"
                  type="text"
                  value={data[index].title}
                  onChange={(event) => handleChange("title", event, index)}
                />
              </label>
            </Form.Field>
            <Form.Field>
              <label>Link to treatment stage</label>
              <Dropdown
                placeholder="Select Page"
                fluid
                selection
                search
                defaultValue={data[index].stage_id}
                selectedLabel={data[index].stage_id}
                options={treatment_stage.map((stage) => ({
                  key: stage.id,
                  value: stage.id,
                  text: stage.title,
                }))}
                onChange={(event, { value }) =>
                  handleChange("treatment_stage", value, index)
                }
              />
            </Form.Field>
          </Form>
          <div style={{ paddingTop: 20 }}>
            <Button
              // style={{ height: 50, width: "100%" }}
              positive
              onClick={() => saveData()}
            >
              Save Timeline
            </Button>
            <Button.Group floated="right" icon>
              <Button
                primary
                onClick={(event) => handleChange("add_card", event, form_index)}
              >
                <Icon name="add" />
              </Button>
              <TimelineModal
                modal_open={modal_open}
                handleOpenModal={handleOpenModal}
                section={section}
                index={index}
                removeSection={removeSection}
              />
            </Button.Group>
          </div>
          <Divider />

          <div>
            <h3>Cards</h3>
            {section.card_array.map((card, index) => {
              return (
                <TimelineCard
                  data={data}
                  handleChangeCard={handleChangeCard}
                  pages={pages}
                  form_index={form_index}
                  index={index}
                  upload={upload}
                  uploadModalOpen={uploadModalOpen}
                  card={card}
                  saveData={saveData}
                  deleteCard={deleteCard}
                  image_update={image_update}
                />
              );
            })}
          </div>
          <UploadModal uploadModalOpen={uploadModalOpen} />
        </Segment>
      );
    });

    return (
      <div>
        <Segment
          style={{ textAlign: "center", background: "#efefef", maxWidth: 600 }}
          color="red"
        >
          <Button positive onClick={() => addNewSection()}>
            Add New Section
          </Button>
        </Segment>

        {form}
        {/* <Button
          style={{ height: 50, width: "100%" }}
          positive
          onClick={() => saveData()}
        >
          Save Timeline
        </Button> */}
      </div>
    );
  }

  return <div id={pathway.id}>{returnTimeline()}</div>;
};

export default Timeline;
