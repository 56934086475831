import React, { useState, useEffect } from "react";

import { Form, Button, Modal, Icon, Divider } from "semantic-ui-react";
const TimelineModal = ({
  modal_open,
  handleOpenModal,
  section,
  index,
  removeSection,
}) => {
  return (
    <div>
      {" "}
      <Modal
        trigger={
          <Button negative onClick={handleOpenModal}>
            <Icon name="trash" />
          </Button>
        }
        open={modal_open}
        //onClose={handleClose}
      >
        <Modal.Header>Are You Sure?</Modal.Header>
        <Modal.Content>
          <Form>
            <Form.Field>
              <h3>Are your sure your want to delete this Timeline Section</h3>
              <p>
                Deleting this section will remove all of the associated cards.
              </p>
              <Divider />
              <Button
                negative
                onClick={() => removeSection(section, index)}
                content="Delete"
              />
              <Button secondary onClick={handleOpenModal} content="Close" />
            </Form.Field>
          </Form>
        </Modal.Content>
      </Modal>
    </div>
  );
};

export default TimelineModal;
