import React, { useState, useEffect } from "react";
import NewPathway from "./NewPathway";

import {
  Accordion,
  Icon,
  Segment,
  Checkbox,
  Button,
  Divider,
  Dropdown,
  Form,
  Message,
  TextArea,
} from "semantic-ui-react";
import _ from "lodash";
import { removePathway, update_Pathway } from "../../actions";
import { useDispatch, useSelector } from "react-redux";
import PathwayDelete from "./PathwayDelete";
import UploadModal from "../../libs/UploadModal";
import s3config from "../../s3config";
import { s3Upload } from "../../libs/awsLib";
import config from "../../config";

import icons from "../../data/icons.json";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { listPathways } from "../../graphql/queries";

const PathwaySection = ({
  pathway,
  handleClick,
  index,
  activeIndex,
  wellnessForms,
  updateActiveIndex,
  all_pathways,
}) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const junctionPages = useSelector((state) => state.junctionPages);
  const pathways = useSelector((state) => state.pathways);
  const [uploadModalOpen, update_uploadModalOpen] = useState(false);
  const [image_url, update_image_url] = useState(pathway.image_url);
  const [title, update_title] = useState(pathway.title);
  const [redirect, update_redirect] = useState(pathway.redirect || null);
  const [cms_title, update_cms_title] = useState(pathway.cms_title || null);
  const hero_section_data = JSON.parse(pathway.hero_section);
  const [hero_title, update_hero_title] = useState(
    hero_section_data?.hero_title || ""
  );
  const [hero_subtitle, update_hero_subtitle] = useState(
    hero_section_data?.hero_subtitle || ""
  );
  const [hero_icon, update_hero_icon] = useState(
    hero_section_data?.hero_icon || ""
  );

  const [wellness_forms_selected, update_wellness_forms_selected] = useState(
    JSON.parse(pathway.wellness_forms_array) || []
  );

  function handleDelete() {
    console.log("delete this thing");
    console.log(pathway);
    console.log(junctionPages);

    var error = false;

    junctionPages.map((junction) => {
      console.log("junction");
      console.log(junction);
      const junction_data_parsed = JSON.parse(junction.junction_data);
      console.log(junction_data_parsed);
      junction_data_parsed.map((junctions) => {
        if (junctions.id === pathway.id) {
          console.log("WE HAVE A MATCH");
          error = true;
          const text =
            "The pathway is being used in '" +
            junction.title +
            "' Please remove beofre deleting";
          console.log(text);
          toast.error(text, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      });
    });
    if (error === false) {
      console.log("delete pathway");
      toast.success("Pathway deleted", {
        position: "top-center",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      updateActiveIndex(-1);
      dispatch(removePathway(pathway));
    }
  }

  function handleImageSelect() {
    console.log("updating image");
    if (image_url !== null) {
      update_image_url(null);
      dispatch(update_Pathway({ id: pathway.id, image_url: null }));
      return;
    }
    return;
  }

  function updateRedirect(value) {}

  function handleSubmit() {
    console.log("...saving");

    dispatch(
      update_Pathway({
        id: pathway.id,
        title: title,
        cms_title: cms_title,
        redirect: redirect,
        image_url: image_url,
        wellness_forms_array: JSON.stringify(wellness_forms_selected),
        hero_section: JSON.stringify({
          hero_icon: hero_icon,
          hero_title: hero_title,
          hero_subtitle: hero_subtitle,
        }),
      })
    );
  }

  function update_wellness_forms(id) {
    var new_array = [...wellness_forms_selected];
    const is_present = _.includes(wellness_forms_selected, id);
    if (is_present) {
      new_array = _.pull(new_array, id);
      update_wellness_forms_selected(new_array);
      return;
    }
    new_array.push(id);
    update_wellness_forms_selected(new_array);
  }

  async function upload(event) {
    console.log("running upload function");
    event.preventDefault();
    update_uploadModalOpen(true);
    const file = event.target.files[0];
    console.log(file);
    if (file && file.size > config.MAX_ATTACHMENT_SIZE) {
      alert(
        `Please pick a file smaller than ${
          config.MAX_ATTACHMENT_SIZE / 1000000
        } MB.`
      );
      return;
    }
    try {
      console.log("trying to upload file");
      const newFile = await s3Upload(file);
      console.log(newFile);
      const public_url = s3config.S3.URL + s3config.S3.EXT + newFile;
      update_uploadModalOpen(false);
      update_image_url(public_url);
      dispatch(update_Pathway({ id: pathway.id, image_url: public_url }));
    } catch (e) {
      alert(e);
      console(e);
    }
  }

  function renderSelectWellnessForms() {
    const forms = wellnessForms.map((form) => {
      const is_present = _.includes(wellness_forms_selected, form.id);
      // console.log("is_present");
      // console.log(is_present);
      return (
        <div>
          {/* {is_present} */}
          <Checkbox
            onClick={() => update_wellness_forms(form.id)}
            label={form.title}
            checked={is_present}
          />
        </div>
      );
    });
    return (
      <Segment secondary>
        <h4>Add Wellness Questions</h4>
        {forms}
      </Segment>
    );
  }

  function renderHeroSection() {
    return (
      <Segment secondary>
        <h2>Hero Section</h2>
        <div>
          <Form>
            <label>Hero Icon</label>
            <Dropdown
              placeholder="Select Icon"
              name="type"
              fluid
              selection
              search
              value={hero_icon}
              options={icons.map((icon) => ({
                key: icon.key,
                value: icon.key,
                text: icon.text,
              }))}
              onChange={(e, { value }) => update_hero_icon(value)}
            />
            <Form.Field>
              <label>Hero title</label>
              <label>
                <input
                  name="hero_title"
                  type="text"
                  value={hero_title}
                  onChange={(event) => update_hero_title(event.target.value)}
                />
              </label>
            </Form.Field>
            <Form.Field>
              <label>Hero Sub Text</label>
              <label>
                <input
                  control={TextArea}
                  name="hero_subtitle"
                  value={hero_subtitle}
                  onChange={(event) => update_hero_subtitle(event.target.value)}
                />
              </label>
            </Form.Field>
          </Form>
        </div>
        <Divider />
      </Segment>
    );
  }

  var dropdown_placeholder = "Please Select Redirect";
  if (redirect !== null) {
    dropdown_placeholder = redirect;
  }

  return (
    <div key={pathway.id}>
      <ToastContainer
        position="top-center"
        autoClose={1500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div>
        <Accordion styled style={{ marginTop: 10 }}>
          <Accordion.Title
            active={activeIndex === index}
            //index={0}
            key={title}
            onClick={() => handleClick(index)}
          >
            <Icon name="dropdown" />
            {cms_title}
          </Accordion.Title>
          <Accordion.Content active={activeIndex === index}>
            <h4>Title: {title}</h4>
            {/* <h4>URL: {pathway.url}</h4> */}
            <p>This is what is displayed on the app and on the onboarding</p>
            <Form>
              <Form.Field>
                <label>
                  <input
                    name="title"
                    type="text"
                    value={title}
                    onChange={(event) => update_title(event.target.value)}
                  />
                </label>
              </Form.Field>
            </Form>
            <Divider />
            <h4>CMS title: {cms_title}</h4>
            <p>This is what is displayed on the cms for reference</p>
            <Form>
              <Form.Field>
                <label>
                  <input
                    name="title"
                    type="text"
                    value={cms_title}
                    onChange={(event) => update_cms_title(event.target.value)}
                  />
                </label>
              </Form.Field>
            </Form>
            <div>
              <Segment secondary>
                <div style={{ textAlign: "center" }}>
                  {/* <h5 style={{ marginBottom: 5 }}>Upload image:</h5> */}
                  <label
                    for={"file:" + pathway.id}
                    className="ui icon button teal"
                  >
                    <i className="file icon"></i>
                    Upload Image
                  </label>
                  <input
                    type="file"
                    id={"file:" + pathway.id}
                    accept="image/png, image/jpeg"
                    onChange={(event) => upload(event)}
                    style={{ display: "none" }}
                  />
                  <UploadModal uploadModalOpen={uploadModalOpen} />
                </div>
              </Segment>

              {image_url !== null ? (
                <>
                  <div>
                    <Segment>
                      {" "}
                      <img
                        style={{
                          border: "none",
                          width: 300,
                          display: "block",
                          margin: "auto",
                        }}
                        alt={pathway.title}
                        src={image_url}
                      />
                      <div>
                        <Button
                          style={{ margin: "auto", display: "block" }}
                          onClick={() => handleImageSelect()}
                        >
                          <Icon name="repeat" />
                          Reset to Trust default!
                        </Button>
                      </div>
                    </Segment>
                  </div>
                </>
              ) : (
                <Segment inverted color="red" secondary>
                  {" "}
                  <div style={{ textAlign: "center" }}>
                    {" "}
                    No image selected. The app will use the Trust default image.
                  </div>
                </Segment>
              )}
            </div>

            <Divider />
            <Segment>
              <h4>Redirect</h4>
              {redirect === null ? (
                <Message positive>
                  <Message.Header>
                    {" "}
                    No redirect selected - This pathway is live
                  </Message.Header>
                  <p>
                    You do not have a redirect selected. Your pathway will
                    therefore publish
                  </p>
                </Message>
              ) : (
                <div>
                  <Message warning>
                    <Message.Header>
                      {" "}
                      You have a redirect selected
                    </Message.Header>
                    <p>
                      You have a redirect selected and your pathway will
                      therefore not publish
                    </p>
                    <div>
                      <Button primary onClick={() => update_redirect(null)}>
                        Remove redirect
                      </Button>
                    </div>
                  </Message>
                </div>
              )}
              <div style={{ marginTop: 20 }}>
                <h4>Redirect Selection:</h4>
                <Dropdown
                  placeholder="Select Pathway to redirect to"
                  name="type"
                  fluid
                  selection
                  search
                  value={dropdown_placeholder}
                  options={all_pathways.map((pathway) => ({
                    key: pathway.id,
                    value: pathway.id,
                    text: `${pathway.title}  (CMS Title: ${pathway.cms_title})`,
                  }))}
                  onChange={(e, { value }) => update_redirect(value)}
                />
              </div>
            </Segment>
            <Divider />
            {renderHeroSection()}
            {renderSelectWellnessForms()}

            <Divider />
            <h2>Technical Information</h2>
            <p>Pathway ID: {pathway.id}</p>
            <p>URL: {pathway.url}</p>
            <p>Hero Section : {JSON.stringify(pathway.hero_section)}</p>
            <p onClick={() => console.log(pathway)}>console log data</p>
            <Button primary onClick={() => handleSubmit()}>
              Save
            </Button>
            {user.user_access !== "basic" ? (
              <div style={{ display: "inline" }}>
                <PathwayDelete
                  deletePathway={handleDelete}
                  pathwayTitle={pathway.title}
                />
              </div>
            ) : (
              <></>
            )}
          </Accordion.Content>
        </Accordion>
      </div>
    </div>
  );
};

export default PathwaySection;
