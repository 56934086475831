import React from "react";
import { Form, Button, Modal, Message } from "semantic-ui-react";

class PageDelete extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      description: "",
      text: "",
      error: false,
      pathwayTitle: this.props.pathwayTitle,
      modalOpen: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleOpen = () => this.setState({ modalOpen: true });
  handleClose = () => this.setState({ modalOpen: false });

  handleChange(event) {
    this.setState({ text: event.target.value });
  }

  handleSubmit = (event) => {
    event.preventDefault();
    if (this.state.text === this.state.pathwayTitle) {
      this.props.deletePathway();
      this.setState({ modalOpen: false });
    } else {
      this.setState({ error: true });
    }
  };

  errorMessage() {
    return (
      <div>
        <Message negative>
          <Message.Header>Unable to delete</Message.Header>
          <p>
            The text entered does not match that requested. Please enter the
            word{" "}
            <strong>
              <code>{this.state.pathwayTitle}</code>
            </strong>{" "}
            to delete the pathway{" "}
          </p>
        </Message>
      </div>
    );
  }

  render() {
    return (
      <Modal
        trigger={
          <Button basic onClick={this.handleOpen}>
            Delete Pathway
          </Button>
        }
        open={this.state.modalOpen}
        onClose={this.handleClose}
      >
        <Modal.Header>Delete Pathway</Modal.Header>
        <Modal.Content>
          {this.state.error ? this.errorMessage() : <div></div>}
          <Form>
            <Form.Field>
              <h3>
                Are your sure your want to delete the pathway
                <i> {this.state.pathwayTitle}</i>?
              </h3>
              <p>
                Deleting this pathwat will remove all of the associated data
                from the database.
              </p>
              <p>
                To permanently delete the pathway, please type{" "}
                <strong>
                  <code>{this.state.pathwayTitle}</code>
                </strong>
                , in the box below
              </p>
              <label>
                Confirm:
                <input
                  name="text"
                  type="text"
                  value={this.state.name}
                  onChange={this.handleChange}
                />
              </label>
              <Button negative onClick={this.handleSubmit} content="Delete" />
              <Button secondary onClick={this.handleClose} content="Close" />
            </Form.Field>
          </Form>
        </Modal.Content>
      </Modal>
    );
  }
}
export default PageDelete;
