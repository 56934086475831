import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addImage } from "../../actions";
import shorthash from "shorthash";
import {
  Button,
  Segment,
  Form,
  Dropdown,
  Divider,
  Icon,
} from "semantic-ui-react";

import ImageModal from "../imageManagement/imageModal";

const TimelineCard = ({
  data,
  handleChangeCard,
  pages,
  form_index,
  index,
  upload,
  uploadModalOpen,
  card,
  saveData,
  deleteCard,
  image_update,
}) => {
  const dispatch = useDispatch();
  const [view, update_view] = useState("preview");
  const [modal_open, update_modal_open] = useState(false);

  const colours = {
    selected: "#0F9E97",
    selected_light: "#cde3e2",
    unselected: "#FDCD52",
    unselected_light: "#fdf5df",
  };

  function image_selected(image) {
    console.log("we are in this function");
    console.log(image);
    image_update(image, index, form_index);
  }

  function changeView() {
    switch (view) {
      case "preview":
        update_view("edit");
        break;
      case "edit":
        update_view("preview");
        break;
      default:
        break;
    }
  }

  function addToImageLibrary(image) {
    const hash = shorthash.unique(image);
    const data = {
      image_url: image,
      tags: "timeline",
      hash: hash,
    };
    dispatch(addImage(data));
  }

  if (view === "preview") {
    return (
      <div style={{ paddingBottom: 20 }} onClick={() => changeView()}>
        <div
          style={{
            borderRadius: 10,
            margin: 10,
            webkitBoxShadow: "0px 0px 5px #9E9E9E",
            mozBoxShadow: "0px 0px 5px #9E9E9E",
            boxShadow: "0px 0px 5px #9E9E9E",
            backgroundColor: "white",
          }}
        >
          <img
            style={{
              margin: 0,
              borderTopRightRadius: 10,
              borderTopLeftRadius: 10,
              padding: 0,
              height: 300,
              border: "none",
            }}
            src={data[form_index].card_array[index].image}
          />

          <div style={{ padding: 20 }}>
            <div style={{ color: "grey", marginBottom: 10 }}>
              {data[form_index].card_array[index].step}
            </div>
            <div
              style={{
                color: "#0c9f96",
                fontSize: 25,
                marginBottom: 10,
              }}
            >
              {data[form_index].card_array[index].title}
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div style={{ paddingBottom: 20 }}>
      <Segment>
        <Form>
          <Form.Field>
            <label>
              Title:
              <input
                name="Card Title"
                type="text"
                value={data[form_index].card_array[index].title}
                onChange={(event) =>
                  handleChangeCard("title", event, form_index, index)
                }
              />
            </label>
          </Form.Field>
          <Form.Field>
            <label>
              Step:
              <input
                name="Step"
                type="text"
                value={data[form_index].card_array[index].step}
                onChange={(event) =>
                  handleChangeCard("step", event, form_index, index)
                }
              />
            </label>
          </Form.Field>
          <label>Link to page</label>
          <Dropdown
            placeholder="Select treatment stage"
            fluid
            selection
            defaultValue={data[form_index].card_array[index].destination}
            selectedLabel={data[form_index].card_array[index].destination}
            options={pages.map((page) => ({
              key: page.page_id,
              value: page.page_id,
              text: page.title,
            }))}
            onChange={(event, { value }) =>
              handleChangeCard("destination", value, form_index, index)
            }
          />
          <Divider />
          <Form.Field>
            {/* <h5>Upload Tile Icon</h5> */}
            {/* <label for={"file:" + card.id} className="ui icon button">
              <i className="file icon"></i>
              Upload Card Image
            </label>
            <input
              type="file"
              id={"file:" + card.id}
              accept="image/png, image/jpeg"
              onChange={(event) => upload(event, form_index, index)}
              style={{ display: "none" }}
            />
            <UploadModal uploadModalOpen={uploadModalOpen} /> */}
            <Button primary onClick={() => update_modal_open(true)}>
              <Icon name="image" />
              Add Image
            </Button>
            <ImageModal
              modal_open={modal_open}
              update_modal_open={update_modal_open}
              image_selected={image_selected}
            />
          </Form.Field>
        </Form>
        <Divider />
        <div style={{ width: "100%", height: 30 }}>
          <Button floated="right" primary onClick={() => changeView()}>
            Preview Card
          </Button>
          <Button
            floated="right"
            negative
            onClick={() => deleteCard(form_index, index)}
          >
            Delete Card
          </Button>
          {/* <Button
            floated="right"
            primary
            onClick={() =>
              addToImageLibrary(data[form_index].card_array[index].image)
            }
          >
            Add Image
          </Button> */}
          <Button
            floated="right"
            primary
            onClick={() => console.log(data[form_index].card_array[index])}
          >
            Log card
          </Button>
          <Button positive onClick={() => saveData()}>
            Save Timeline
          </Button>
        </div>
      </Segment>
    </div>
  );
};

export default TimelineCard;
