import React, { useState, useEffect } from 'react';
import { Form, Button, Modal, Divider } from 'semantic-ui-react'
import { connect } from 'react-redux';
import _ from 'lodash';

import {addPathway} from  '../../actions'

import { useDispatch, useSelector } from "react-redux";



const NewPathway = ({updateModalOpen, modalOpen}) => {
    const [title, updateTitle] = useState("")
    // const [modalOpen, updateModalOpen] = useState(false)

    const user = useSelector(state => state.user);
    const dispatch = useDispatch();


    function handleSubmit (event) {
      const this_user = user["custom:trust"]
      dispatch(addPathway(title, this_user))
      updateTitle("")
      updateModalOpen(false)
    }
    


  return (
    <div>
      <Modal
        size='mini'
        trigger={<Button 
          primary 
          onClick={() => updateModalOpen(true)}
        >New Pathway</Button>}
        open={modalOpen}
        // onClose={this.handleClose}
        >
        <Modal.Header>Add New Pathway</Modal.Header>
        <Modal.Content>
          <Form>
            <Form.Field>
              <label>
                Name:
                <input
                  name="title"
                  type="text"
                  value={title}
                  onChange={(event) => updateTitle(event.target.value)}
                />
              </label>
              <Divider/>
              <Button
                primary
                onClick={()=> handleSubmit()}
                content="Save"
              />
              <Button
                secondary
                onClick={() => updateModalOpen(false)}
                content="Close"
              />
            </Form.Field>
          </Form>
        </Modal.Content>
      </Modal>
      
    </div>
  )
}

export default NewPathway





// import React from 'react'
// import { Form, Button, Modal, Divider } from 'semantic-ui-react'
// import { connect } from 'react-redux';
// import _ from 'lodash';

// import { addTile } from '../../actions';

// class TileNew extends React.Component {
//   constructor(props) {
//   super(props);
//   this.state = {description: '',
//                 title: '',
//                 modalOpen: false,
//                 user: props.user};
//   this.handleChange = this.handleChange.bind(this);
//   this.handleSubmit = this.handleSubmit.bind(this);
//   }

//   handleOpen = () => this.setState({
//     modalOpen: true,
//     description: '',
//     title: ''
//   })
//   handleClose = () => this.setState({ modalOpen: false })


//   handleChange(event) {
//     const name = event.target.name;
//     this.setState({[name]: event.target.value});
//   }


//   handleSubmit = (event) => {
//     event.preventDefault();
//     const total_tiles = _.sortedIndex(this.props.tiles)
//     const params = {
//       Item: {
//         title: this.state.title,
//         colour: '#0c9f96',
//         image_url: "https://mysunrise-staging-images-test.s3-eu-west-1.amazonaws.com/images/uXuFyWnrMQpkfzQne2jgKZ.png",
//         destination: null,
//         destination_name: null,
//         index_number: total_tiles
//       }
//     };
//     this.props.addTile(params, this.state.user['custom:trust']);
//       this.setState({ modalOpen: false })
//     }


//   render() {
//     return (
//       <Modal
//         size='mini'
//         trigger={<Button primary onClick={this.handleOpen}>New Tile</Button>}
//         open={this.state.modalOpen}
//         onClose={this.handleClose}
//         >
//         <Modal.Header>Add New Tile</Modal.Header>
//         <Modal.Content>
//           <Form>
//             <Form.Field>
//               <label>
//                 Name:
//                 <input
//                   name="title"
//                   type="text"
//                   value={this.state.title}
//                   onChange={this.handleChange}
//                 />
//               </label>
//               <Divider/>
//               <Button
//                 primary
//                 onClick={this.handleSubmit}
//                 content="Save"
//               />
//               <Button
//                 secondary
//                 onClick={this.handleClose}
//                 content="Close"
//               />
//             </Form.Field>
//           </Form>
//         </Modal.Content>
//       </Modal>
//     )
//   }
// }
// const mapStateToProps = state => {
//   return { tiles: state.tiles};
// };

// export default connect(mapStateToProps, { addTile })(TileNew);
