import React, { useState, useEffect } from "react";
import { Segment, Grid, Dimmer, Loader } from "semantic-ui-react";

import NewPathway from "./NewPathway";
import { API, graphqlOperation } from "aws-amplify";
import { pathwayByUserId } from "../../graphql/queries";
import {
  fetchJunctionPages,
  fetchPathways,
  fetchWellnessForms,
} from "../../actions";
import { useDispatch, useSelector } from "react-redux";

import PathwaySection from "./PathwaySection";
import Timeline from "./Timeline";

const Index = () => {
  const [loading, updateloading] = useState(true);
  const [modalOpen, updateModalOpen] = useState(false);
  const wellnessForms = useSelector((state) => state.wellnessForm);
  // const pathways = useSelector((state) => state.pathways);
  const [pathways, updatepathways] = useState([]);
  const user = useSelector((state) => state.user);

  const [activeIndex, updateActiveIndex] = useState(-1);

  const dispatch = useDispatch();

  useEffect(() => {
    // dispatch(fetchPathways(user["custom:trust"]));
    getData();
    dispatch(fetchWellnessForms(user["custom:trust"]));
    dispatch(fetchJunctionPages(user["custom:trust"]));
    updateloading(false);
  }, []);
  useEffect(() => {
    getData();
  }, [modalOpen]);
  async function getData() {
    const response = await API.graphql(
      graphqlOperation(pathwayByUserId, { user_id: user["custom:trust"] })
    );
    updatepathways(response.data.PathwayByUserId.items);
  }

  function handleClick(index) {
    if (activeIndex === index) {
      updateActiveIndex(-1);
      return;
    }
    updateActiveIndex(index);
  }

  function renderPathways() {
    const pathway_sections = pathways.map((pathway, index) => {
      // return <div>{pathway.title}</div>
      console.log(pathway.id);
      return (
        <PathwaySection
          all_pathways={pathways}
          key={pathway.id}
          pathway={pathway}
          handleClick={handleClick}
          index={index}
          activeIndex={activeIndex}
          wellnessForms={wellnessForms}
          updateActiveIndex={updateActiveIndex}
        />
      );
    });
    return <div>{pathway_sections}</div>;
  }

  function returnPreview() {
    if (activeIndex === -1) {
      return <div>Select a pathway to preview</div>;
    }
    return (
      <div>
        <Timeline pathway={pathways[activeIndex]} />
      </div>
    );
  }
  if (loading) {
    return (
      <Dimmer active inverted>
        <Loader size="medium">Loading Pathways</Loader>
      </Dimmer>
    );
  }
  return (
    <div>
      <h1>Pathways</h1>
      <Grid columns={2} divided>
        <Grid.Row>
          <Grid.Column>
            <Segment
              style={{
                textAlign: "center",
                background: "#efefef",
                maxWidth: 600,
              }}
              color="blue"
            >
              <NewPathway
                modalOpen={modalOpen}
                updateModalOpen={updateModalOpen}
              />
            </Segment>
            <div>{renderPathways()}</div>
          </Grid.Column>
          <Grid.Column>{returnPreview()}</Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
};

export default Index;
